
.map-marker {
  z-index: 1000;  /* Ensure markers are on top */
  pointer-events: auto;  /* Ensure markers can receive pointer events */
}

.hover-popup {
  background-color: #282c34;;  /* Green theme with slight transparency */
  color: white;  /* White text for readability */
  padding: 8px;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  font-size: 12px;
  text-align: center;
  word-wrap: break-word;  /* Ensure long text wraps within the container */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);  /* Shadow for better visibility */
  z-index: 1000;  /* Ensure it's above other elements */
}

#geocoder-container {
  width: 100%;
  max-width: 400px; /* Adjust width as needed */
  margin: 10px auto; /* Center the input box */
}

.mapboxgl-ctrl-geocoder {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 2;
}
