.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-custom {
  display: flex;
  justify-content: space-between; /* Space between title/logo and burger menu */
  align-items: center;
  background-color: #333; /* Adjust as needed */
  padding: 10px 20px; /* Add some padding */
}

.navbar-brand {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
}

.navbar-brand img {
  max-height: 40px;
  margin-right: 10px;
}
@media (max-width: 480px) {
  .navbar-brand span {
    display: none; /* Hide title text on very small screens */
  }
}

@media (max-width: 768px) {
  .page-container {
    padding: 10px 15px; /* Adjust padding for smaller screens */
  }
}


.button-custom {
  background-color: #e60101;
  border-color: #ff0048;
}

.button-custom:hover {
  background-color: #444; /* Darker button background */
  border-color: #666; /* Darker border */
}


.tooltip {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px;
  border-radius: 4px;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.burger-menu {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

.menu-container {
  position: absolute;
  top: 60px;
  right: 10px;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1500; /* Ensures it appears above the map */
  width: 200px;
}

.menu-container a {
  text-decoration: none;
  color: white;
  font-size: 18px;
  padding: 10px;
  border-radius: 4px;
  background: #444; /* Button-like background */
  text-align: center;
}

.menu-container a:hover {
  background: #666;
}

.page-container {
  padding: 20px;
  margin: 0 auto;
  max-width: 800px; /* Limit the width for better readability */
}

.page-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.page-container p,
.page-container ul {
  line-height: 1.6;
  font-size: 16px;
}

.page-container h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.5em;
}

.page-container ul {
  list-style-type: disc;
  padding-left: 20px;
}

.page-container ul li {
  margin-bottom: 10px;
}

.page-container ul li strong {
  color: #007bff; /* Highlighted color for magnitude types */
}
