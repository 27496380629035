body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #181818; /* Darker background */
  color: #e0e0e0; /* Light text color for contrast */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Navbar styling */
.app-container .navbar-custom {
  background-color: #1E3A5F; /* Deep blue background for navbar */
  padding: 10px; /* Padding for navbar */
}

.app-container .navbar-custom .navbar-brand {
  color: #ffffff; /* White text for brand */
  font-size: 1.5em; /* Larger font size for the title */
}

.map-marker {
  background-image: url('../public/earthquake.png');
  background-size: cover;
  width: 50px; /* Default width */
  height: 50px; /* Default height */
  border-radius: 50%; /* Circular icon */
  cursor: pointer;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.map-container {
  height: 60vh; 
  width: 100%;
  position: relative;
  z-index: 1;
}

.sort-button {
  background: none;
  border: none;
  color: #ffffff; /* Button color */
  cursor: pointer;
  font-size: 16px;
  transition: color 0.3s;
}

.sort-button:hover {
  color: #66ccff; /* Lighter blue on hover */
}

.sort-button:focus {
  outline: none; /* Remove outline on focus */
}

.table-hover tbody tr:hover {
  background-color: #444444; /* Darker hover background for better visibility */
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #3a3a3a; /* Darker alternate row colors */
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #2c2c2c; /* Slightly lighter for even rows */
}

.table {
  width: 100%; /* Full width */
  border-collapse: collapse; /* Remove space between borders */
  border: 1px solid #555; /* Light border color */
}

.table th {
  background-color: #2c2c2c; /* Lighter header background for contrast */
  color: #ffffff; /* White text color */
  padding: 12px; /* Padding for header cells */
}

.table td {
  color: #e0e0e0; /* Light grey text for the table body */
  padding: 12px; /* Padding for cells */
  vertical-align: middle; /* Vertically center content */
  border: 1px solid #555; /* Light border for cells */
}

@media (max-width: 600px) {
  .table {
    display: block; /* Block layout on small screens */
    overflow-x: auto; /* Allow horizontal scrolling */
  }
}

.header-container {
  display: flex; /* Use flexbox for alignment */
  justify-content: space-between; /* Space out elements */
  align-items: center; /* Center vertically */
}
